@import 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,400i,600,600i,700';

@font-face {
  font-family: Aeonik;
  src: url('./fonts/Aeonik-Regular.woff2');
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl-Regular.otf');
}

@font-face {
  font-family: 'Suisse Intl';
  font-weight: 600;
  src: url('./fonts/SuisseIntl-SemiBold.otf');
}

:root {
  --color--indigo: #4263eb;
  --color--purple: #8732de;
  --color--magenta: #da457c;
  --color--orange: #e7803a;
  --color--yellow: #f7e559;

  --color--karl: #94a3b5;
  --color--frost: #dae0e7;
  --color--dawn: #edf0f4;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: inherit;
}

.StripeElement {
  border: 1px solid var(--color--frost);
  border-radius: 4px;
  margin-top: 5px;
  outline: none;
  padding: 10px 10px 11px;
}

.StripeElement--focus {
  border: 1px solid var(--color--indigo);
  box-shadow: 0px 0px 0px 1px var(--color--indigo) inset;
}

.StripeElement:hover:not(.StripeElement--focus) {
  border-color: var(--color--karl);
  box-shadow: 0px 0px 0px 1px var(--color--karl) inset;
}

.StripeElement--invalid:not(.StripeElement--focus) {
  border-color: var(--color--magenta);
  box-shadow: 0px 0px 0px 1px var(var(--color--magenta)) inset;
}

.ReactModal__Overlay {
  z-index: 2;
}
